* {
  box-sizing: border-box;
}

:root {
  background: #3a4d39;
  font-family: Verdana, sans-serif;
}

a {
  color: #3a4d39;
  text-decoration: none;
}

a:hover {
  color: #739072;
}

label.checkbox {
  width: auto;
  margin-bottom: .5rem;
}

label.checkbox input {
  width: auto;
  display: inline;
}

html, body {
  height: 100%;
}

body {
  flex-direction: column;
  padding-top: 1rem;
  display: flex;
}

body > main {
  flex: 1 0 auto;
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
}

body > main > h1 {
  color: #ece3ce;
  text-shadow: 1px 1px 5px #000;
}

body > footer {
  background: #ece3ce;
  flex-flow: wrap;
  align-items: stretch;
  padding: .8rem;
  font-size: .8rem;
  display: flex;
}

body > footer > p {
  width: 50%;
  margin: 0;
  padding: 0;
  display: inline-block;
}

body > footer > .copy {
  text-align: right;
}

section {
  background: #ece3ce;
  border-radius: 5px;
  margin-bottom: 1rem;
  padding: 1rem;
  box-shadow: 1px 1px 1px #000;
}

input {
  border: none;
  border-radius: 5px;
  max-width: 100%;
  margin: .5rem .5rem .5rem 0;
  padding: .5rem;
}

button {
  color: #ece3ce;
  cursor: pointer;
  background: #4f6f52;
  border: none;
  border-radius: 5px;
  margin: .5rem .5rem .5rem 0;
  padding: .5rem;
}

button:hover {
  background: #739072;
  border: none;
}

.error-container {
  color: #dc143c;
  background: #f003;
  border-radius: 5px;
  margin: 1rem 0;
  padding: 1rem;
  display: none;
}

.registered-players {
  padding: 0;
}

.registered-players li {
  color: #ece3ce;
  background: #4f6f52;
  border-radius: 5px;
  margin: .5rem .5rem .5rem 0;
  padding-left: .5rem;
  list-style-type: none;
  display: inline-block;
}

.registered-players .remove-player {
  display: inline;
}

.registered-players .remove-player button {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  margin: 0;
  display: inline-block;
}

.score-board {
  overflow-y: auto;
}

table {
  width: 100%;
}

thead th {
  border-bottom: 1px solid #000;
  border-right: 1px solid #000;
  padding: .5rem;
}

tbody th {
  border-right: 1px solid #000;
}

table td {
  border-bottom: 1px solid #000;
  border-right: 1px solid #000;
  padding: 1rem;
}

.enter-results-form label, .submit-guesses-form label, .enter-results-form input, .submit-guesses-form input {
  width: 100%;
  display: block;
}

/*# sourceMappingURL=index.23772f0a.css.map */
