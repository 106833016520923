* {
    box-sizing: border-box;
}

:root {
    background: #3A4D39;
    font-family: Verdana,sans-serif;
}

a {
    color: #3A4D39;
    text-decoration: none;
}

a:hover {
    color: #739072;
}

label.checkbox {
    width: auto;
    margin-bottom: .5rem;
}

label.checkbox input {
    width: auto;
    display: inline;
}

html,body {
    height: 100%;
}

body {
    display: flex;
    flex-direction: column;
    padding-top: 1rem;
}

body > main {
    flex: 1 0 auto;
    width: 100%;
    max-width: 800px;
    margin: 0 auto;
}

body > main > h1 {
    color: #ECE3CE;
    text-shadow: 1px 1px 5px #000;
}

body > footer {
    background: #ECE3CE;
    padding: .8rem;
    font-size: .8rem;
    display: flex;
    flex-flow: row wrap;
    align-items: stretch;
}

body > footer > p {
    display: inline-block;
    width: 50%;
    margin: 0;
    padding: 0;
}

body > footer > .copy {
    text-align: right;
}

section {
    padding: 1rem;
    margin-bottom: 1rem;
    background: #ECE3CE;
    box-shadow: 1px 1px 1px #000;
    border-radius: 5px;
}

input {
    padding: .5rem;
    margin: .5rem .5rem .5rem 0;
    border: none;
    border-radius: 5px;
    max-width: 100%;
}

button {
    padding: .5rem;
    margin: .5rem .5rem .5rem 0;
    border-radius: 5px;
    background: #4F6F52;
    color: #ECE3CE;
    cursor: pointer;
    border: none;
}

button:hover {
    background: #739072;
    border: none;
}

.error-container {
    margin: 1rem 0;
    padding: 1rem;
    background: rgba(255,0,0,.2);
    border-radius: 5px;
    color: crimson;
    display: none;
}

.registered-players {
    padding: 0;
}

.registered-players li {
    list-style-type: none;
    margin: .5rem .5rem .5rem 0;
    padding-left: .5rem;
    border-radius: 5px;
    background: #4F6F52;
    color: #ECE3CE;
    display: inline-block;
}

.registered-players .remove-player {
    display: inline;
}
.registered-players .remove-player button {
    display: inline-block;
    margin: 0;
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
}

.score-board {
    overflow-y: auto;
}

table {
    width: 100%;
}

thead th {
    border-right: 1px solid #000;
    border-bottom: 1px solid #000;
    padding: .5rem;
}

tbody th {
    border-right: 1px solid #000;
}

table td {
    border-right: 1px solid #000;
    border-bottom: 1px solid #000;
    padding: 1rem;
}

.enter-results-form label, .submit-guesses-form label,
.enter-results-form input, .submit-guesses-form input {
    display: block;
    width: 100%;
}